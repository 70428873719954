import './App.css';
import NavBar from "./components/NavBar/NavBar";
import PropertyList from './components/propertylist/Propertylist';
import Home from './pages/Home';
import {Routes,Route} from 'react-router-dom';
import AboutUspage from './pages/AboutUspage';
import Auctionpage from './pages/Auctionpage';
import Bankproperties from './pages/Bankproperties';
import ContactUspage from './pages/ContactUspage';

function App() {

  return (
    <div className="App">
      {/* Define routes */}
      <Routes>
        <Route path='/' element={ < Home/>} />
        <Route path='/proprty-list'element={<PropertyList/>}  />
        <Route path='/AboutUspage' element={<AboutUspage/>}    />
        <Route path='/Auctionpage ' element={<Auctionpage/>}    />
        <Route path='/Bankproperties' element={<Bankproperties/>}  />
        <Route path='/ContactUspage' element={<ContactUspage/>} />
        
      

      
        
      </Routes>
      
    </div>
  );
}

export default App;
