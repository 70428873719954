// Bankproperties.js
import React from "react";

function Bankproperties() {
    return (
        <div>
            <h8>Bankproperties</h8>
            {/* Add home page content here */}
        </div>
    );
}

export default Bankproperties;
