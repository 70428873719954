import React from "react";
import NavBar from "../components/NavBar/NavBar";
import Hero from "../components/Hero/Hero";
import Product from "../components/Products/Product";
import UpcomingAuction from "../components/UpcomingAuctions/UpAuction";
import CityImages from "./../components/CityImages/CityImages";
import OurPartners from "./../components/our partners/OurPartners"
import Footer from "../components/footer/Footer";


function Home ()  {
    return(
        <div>
            <NavBar/>
            <Hero/>
            <Product/>
            <UpcomingAuction/>
            <CityImages/>
            <OurPartners/>
            <Footer/>
            
            
            
            
            
            
        </div> 
    )
    }

    
export default  Home 