// PropertyList.js

import React, {useState} from 'react';
import NavBar from './../NavBar/NavBar'
import Footer from './../footer/Footer'


const propertiesData = [
    {
        id: 1,
        description: "A light industrial land measuring 10.0 acres developed Industrial godowns, show room and stores blocks and 1 acre under staff residential houses situated opposite Ivuli Chetu Prayer Centre behind Sigona Golf Club in Kikuyu twonship area- Kiambu county",
        guidePrice: "563M",
        saleDate: "11.06.2024"
    },
    {
        id: 2,
        description: "Commercial/ Agricultural Property measuring 5.31 acres developed with a Shopping Centre comprising Four storey house and a store and chicken shed and the rest of land vacant situated along Kiserian - Isinya road - Kajiado County",
        guidePrice: "21.0M",
        saleDate: "11.06.2024"
    },
    {
        id: 3,
        description: "Residential property measuring 0.1 acres developed with a four storey residential flats (rental units) comprising 13 no. 1 bedroom units and 12 no, 2 bedroom units plus 1 no. shop situated off Kakamega -Mumias Road within Murram Estate - Kakamega town - Kakamega County",
        guidePrice: "16.5M",
        saleDate: "07.06.2024"
    },
    {
        id: 4,
        description: "Commercial property measuring 0.0988 acres developed with a Commercial cum residential property comprising 3 no. shops and 2 no. double rooms situated within Manyatta Market Centre - Kisumu City - Kisumu County",
        guidePrice: "4.2M",
        saleDate: "06.06.2024"
    },
    {
        id: 5,
        description: "Industrial/ commercial property (Godown) measuring 0.33 acres developed with a three (3) storey Godown situated within Athi 55 Business complex Park (directly opposite Signature Mall) along Mombasa road, Athi River area- Machakos County",
        guidePrice: "64.0M",
        saleDate: "28.05.2024"
    },
    {
        id: 6,
        description: "Residential property (Rental units) measuring 0.1416 acres developed with a four (4) storey rental block of five (5) no. two bedroom units on each floor (totaling to 20 units) situated within Bosnia area, Githurai 45 - Kiambu County",
        guidePrice: "29.8M",
        saleDate: "28.05.2024"
    },
    {
        id: 7,
        description: "Two commercial plots measuring 0.26 acres and 0.25 acres developed jointly with a single storey building (under construction) situated within Kibiku area along Gitaru - Ndenderu Road, Wangige - Kiambu County",
        guidePrice: "19.5M",
        saleDate: "28.05.2024"
    },
    {
        id: 8,
        description: "Industrial property measuring 1.93 acres developed with two (2) blocks of double span warehouses & double storied administration block situated 600 meters off Garissa road in Makongeni, Thika town - Kiambu County",
        guidePrice: "312.0M",
        saleDate: "28.05.2024"
    },
    {
        id: 9,
        description: "Industrial property measuring 2.45 acres developed with three (3) blocks of double span warehouses situated one km off Garissa road in Makongeni, Thika town - Kiambu County",
        guidePrice: "722.0M",
        saleDate: "28.05.2024"
    },
    {
        id: 10,
        description: "Residential property measuring 0.0712 acres developed with a three bedroom bungalow (master ensuite) situated in Salama area (Mastore) in Juja - Kiambu County",
        guidePrice: "3.95M",
        saleDate: "28.05.2024"
    },
    {
        id: 11,
        description: "Residential property measuring 0.445 acres developed with a four (4) bedroom (all ensuite) double storey house (maisonette) situated within Jamboni area, Eldoret Town - Uasin Gishu County",
        guidePrice: "9.8M",
        saleDate: "24.05.2024"
    },
    {
        id: 12,
        description: "Undeveloped residential plot measuring 0.593 acres in Memusi area of lower Matasia, Ngong Kajiado County",
        guidePrice: "7.5M",
        saleDate: "21.05.2024"
    },
    {
        id: 13,
        description: "Undeveloped residential plot measuring 1.26 acres in Memusi area of lower Matasia, Ngong Kajiado County",
        guidePrice: "15.0M",
        saleDate: "21.05.2024"
    },
    {
        id: 14,
        description: "Eleven (11) vacant Industrial properties measuring 0.23 acres each situated on Enterprises road, Industrial area - Nairobi County",
        guidePrice: "23.0M each",
        saleDate: "14.05.2024"
    },
    {
        id: 15,
        description: "Industrial property measuring 2.36 acres developed with an office block, Godown and industrial shed situated off Enterprises road, Industrial area - Nairobi County",
        guidePrice: "265.0M",
        saleDate: "14.05.2024"
    }
];
    


const PropertyList = () => {
    const handleDownload = () => {
        // Create a CSV string from propertiesData
        const csv = propertiesData.map(property => Object.values(property).join(',')).join('\n');

        // Create a Blob with the CSV data
        const blob = new Blob([csv], { type: 'text/csv' });

        // Create a link element to download the Blob
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'property_list.csv';

        // Simulate a click on the link to trigger the download


        const PropertyList = () => {
            const [showPropertyList, setShowPropertyList] = useState(false);

            const handleDownload = () => {
                setShowPropertyList(true);
            };
        }
    };

    return (
        <div>
            <NavBar />
            <table>
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Description</th>
                        <th>Guide Price (KSHS)</th>
                        <th>Sale Date</th>
                    </tr>
                </thead>
                <tbody>
                    {propertiesData.map(property => (
                        <tr key={property.id}>
                            <td>{property.id}</td>
                            <td>{property.description}</td>
                            <td>{property.guidePrice}</td>
                            <td>{property.saleDate}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Footer />
        </div>
    );
}

export default PropertyList;


