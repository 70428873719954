
import React from "react"
import "./CityImages.css"

function CityImages() {
    return (
        <div className="city-background-container">
            <div className="city-parent-text">
            
                
                {/* <img src="/Assets/Images/bcg-img4.jpg" alt="CityBuilding" /> */}
                
                
            </div>
            <div className="text-overlay">
                
                <h4>DOWNLOAD APRIL 2024 </h4>
                <p>Upcoming Properties List!.</p>
            </div>
        </div>
    );
}

export default CityImages;
