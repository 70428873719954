// ContactUspage.js
import React from "react";

function ContactUspage() {
    return (
        <div>
            <h2>ContactUspage</h2>
            {/* Add home page content here */}
        </div>
    );
}

export default ContactUspage;
