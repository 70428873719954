import React from 'react';
import { Link } from 'react-router-dom'; 
import './Footer.css';
const Footer = () => {
    return (
        <footer>
            <div className="sub-title">
                <h6>HONEST, EFFICIENT, AND DILIGENT</h6>
            </div>
            <div className="business-hours">
                <p>Business Hours:</p>
                <ul>
                    <li>Monday - Friday: 9:00 AM - 5:00 PM</li>
                    <li>Saturday: 10:00 AM - 2:00 PM</li>
                    <li>Sunday: Closed</li>
                </ul>
            </div>
            <div className="properties-list">
                <p>Featured Properties:</p>
                <ul>
                    
                
        
                </ul>
            </div>
            <div className="online-auction">
                <p>Online Auction:</p>
                <p>Bid on our properties online</p>

                <Link to={'propertylist'}>Download propertylist</Link>

            </div>
        </footer>
    );
};

export default Footer;
