
import React from 'react';
import './UpAuction.css';

function UpcomingAuction() {
    return (
        <div className="upcoming-auction-container">
            <div className="auction-item">
                <img src="/Assets/Images/bcg-img 1.jpg" alt="laptops" />
                <p>Onemark Auctioneers Desktop Computers for sale</p>
            </div>

            <div className="auction-item">
                <img src="/Assets/Images/bag-img2.jpg" alt="Furniture Equipments" />
                <p>Auction Large Assortment of Household and office Equipments and Furniture For Sale</p>
            </div>

            <div className="auction-item">
                <img src="/Assets/Images/bcg-img3.jpg" alt="Industrial Equipments" />
                <p>Auction Large Industrial Equipments For Sale</p>
            </div>
        </div>
    );
}

export default UpcomingAuction;