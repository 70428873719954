import React from "react";
import "./NavBar.css"
import { NavLink } from "react-router-dom";

function NavBar() {
    return (
        <div>
            <nav>
            
            <img src="/Assets/Images/onemark-auctioneers-logo-png.png"  alt="logo" className="'logo"/>
            
            <menu>
                <li> <NavLink to={'/'}>home</NavLink> </li>
                <li><NavLink to={'/AboutUs'}> </NavLink>Aboutus</li>
                <li><NavLink to={'/Auctions'}></NavLink>Auctions  </li>
                <li><NavLink to={'/Bankproperties'}></NavLink>Bank Properties</li>
                <li><NavLink to={'/ContactUs'}></NavLink>Contact us</li>
            </menu>
            </nav>
        </div>
    )

}

export default NavBar