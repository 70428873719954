import React from "react";
import { FaPiggyBank, FaCar, FaRegFolder, } from "react-icons/fa6"
import "./Product.css"

function Product() {
    return (
        <div className="prod-container">

            <div className="product">
                <FaRegFolder className="product-Icon" />
                <h3 className="prod-name">Bank Properties</h3>
                <p className="prod-description">Search and view thousands of properies for sale </p>
            </div>

            <div className="product">
                <FaCar className="product-Icon" />
                <h3 className="prod-name">Motor Vechiles</h3>
                <p className="prod-description">Reprossessed and salvage motor vehicles for sale  </p>
            </div>
            
            <div className="product">
                < FaPiggyBank className="product-Icon" />
                <h3 className="prod-name">Office Equipments</h3>
                <p className="prod-description">Office equipments, furniture household, goods and industrial equipments for sale </p>
            </div>

            {/*
            {/*

            <FaFolder/>
            <FaPiggyBank/> */}
        </div>

    )
}
export default Product