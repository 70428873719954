// Auctionpage.js
import React from "react";

function Auctionpage() {
    return (
        <div>
            <h2>Auctionpage</h2>
            {/* Add home page content here */}
        </div>
    );
}

export default Auctionpage;