import React from "react" 
    import "./Hero.css"

    function Hero () {
        return (
            <div className="hero-bcg-img">
                <div className="hero-parent-text">
                    <h1>Onemark Auctioneers</h1>
                    <h1>Honest, Efficient & Diligent</h1>
                    <h2>for properties, vehicles,office, household equipment and furniture </h2>
                <button className="hero-btn">Learn More</button>
                </div>
            </div>
        )
    }

    export  default Hero;
    

    
