// AboutUspage.js
import React from "react";

function AboutUspage() {
    return (
        <div>
            <h2>AboutUspage</h2>
            {/* Add home page content here */}
        </div>
    );
}

export default AboutUspage;
