import React from 'react';
import "./OurPartners.css";

const Partners = () => {
    // Array of partner images
    const partnerImages = [
        { id: 1, src: '/Assets/Images/bank-of-india.png', alt: 'partner 1 ' }, 
        { id: 2, src: '/Assets/Images/bankofbarodanew.png', alt: 'partner 2' },
        { id: 3, src: '/Assets/Images/batnew.png', alt: 'partner 3'},
        { id: 4, src: '/Assets/Images/britamnew.png', alt:'partner 4'},
        { id: 5, src: '/Assets/Images/cbknew.png', alt:'partner 5' },
        { id: 6, src: '/Assets/Images/citibanknew.png', alt: 'partner 6'},
        { id: 7, src: '/Assets/Images/coopbanknew.png', alt: 'partner 7'},
        { id: 8, src: '/Assets/Images/dtb.png', alt: 'partner 8'},
        { id: 9, src: '/Assets/Images/iandm.png', alt: 'partner 9'},
        { id: 10,src: '/Assets/Images/knightfrank.png', alt:'partner 10'},
        { id: 11,src: '/Assets/Images/kra.png', alt:'partner 11'},
        { id: 12,src: '/Assets/Images/mua-3.png', alt:'partner 12'},
        { id: 13,src: '/Assets/Images/nationalbank.png', alt:'partner 13'},
        { id: 14,src: '/Assets/Images/oxfarm.png', alt:'partner 14'},
        { id: 15,src: '/Assets/Images/sbmbanknew.png', alt:'partner 15'},
        { id: 16,src: '/Assets/Images/stanbicbank.png', alt:'partner 16' },
        { id: 17,src: '/Assets/Images/telkom.png', alt:'partner 17'},
        { id: 18,src: '/Assets/Images/ubabknew (1).png', alt:'partner 18' },
        { id: 19,src: '/Assets/equitynew.png', alt:'partner19'},
        // Add more partner images as needed
    ];

    return (
        <div>
            <h5 className='our-partners-title'>Our Partners</h5>
            <div className="partner-container">
              <img src="/Assets/Images/TECH-CRAST.png" alt="Techcrast" />
            </div>
        </div>
    );
};

export default Partners;

